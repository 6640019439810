import {IConfig} from './Config';

export const config: IConfig = {
  firebaseConfig: {
    apiKey: 'AIzaSyBcvHAivShGttTc-oRdh3414qqDPJ_yPRA',
    authDomain: 'dwz-admin.chancerhq.com',
    databaseURL: 'https://chancerhq-development.firebaseio.com',
    projectId: 'chancerhq-development',
    storageBucket: 'chancerhq-development.appspot.com',
    messagingSenderId: '520043420628',
    appId: '1:520043420628:web:50cfc6a653dc26fd51d441',
    measurementId: 'G-S4X4WQKJQC',
  },
  webDomain: 'dwz-www.chancerhq.com',
  appDomain: 'dwz-app.chancerhq.com',
  adminDomain: 'dwz-admin.chancerhq.com',
  adminDomainBypassHosting: 'serveadmin-4md5uk7jua-uc.a.run.app',
  cdnDomain: 'dwz-cdn.chancerhq.com',
  environment: 'development',
};
